.footer {
  background-color: #f9f9f9;
  /* display: flex;
  flex-direction: column; */
  /* padding: 35px; */
  width: 100%;
  max-width: 100vw;
  @media (max-width: 991px) {
    padding: 0px;
  }
}
.topContent {
  display: flex;
  & .linkDiv {
    flex-basis: 70%;
    display: flex;
    @media (max-width: 1199px) {
      flex-basis: 60%;
    }
    @media (max-width: 991px) {
      flex-direction: column;
      /* padding: 15px; */
    }

    & .logoLink {
      flex-basis: 30%;
      & > div {
        margin: 0;
        height: auto;

        & > img {
          width: 60px;
          height: 20px;
        }
      }
      & > a {
        display: inline-block;
        & > svg {
          width: 100px;
          height: auto;
          & > g {
            & > rect{
              fill: #222a36 !important;
            }
          }
      
          @media (max-width: 991px) {
            width: 65px;
            height: auto;

          }
        }
      }
    }
    & .linkColumn {
      display: flex;
      flex-direction: column;
      @media (max-width: 991px) {
        margin-top: 35px;
      }

      & > span {
        font-size: 12px;
        color: #000000;
        cursor: pointer;
        &:hover {
          text-decoration: none;
        }

        @media (max-width: 991px) {
          border-top: 1px solid #e4e4e4;
          padding: 10px 0;
        }
      }
      & > a {
        font-size: 12px;
        color: #000000;
        cursor: pointer;
        &:hover {
          text-decoration: none;
        }
        @media (max-width: 991px) {
          border-top: 1px solid #e4e4e4;
          padding: 10px 0;
        }

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  & .connectDiv {
    flex-basis: 30%;
    display: flex;
    @media (max-width: 1199px) {
      flex-basis: 40%;
    }
    @media (max-width: 991px) {
      flex-direction: column;
      padding: 25px 0;
      border-top: 1px solid #e4e4e4;
      border-bottom: 1px solid #e4e4e4;
    }

    & > div:nth-child(1) {
      flex-basis: 70%;
      & > span {
        font-size: 16px;
        color: #000000;
        margin: 0;
        font-weight: 500;
      }
      & > label {
        font-size: 12px;
        color: #000000;
        margin-top: 19px;
        padding: 0;
        font-weight: 400;
        font-family: var(--fontFamilyHelvetica);
      }
      & > input {
        background: transparent;
        border: none;
        border-bottom: 1px solid #000;
        border-radius: 0;
        padding: 0;
        line-height: 30px;
        font-weight: 400;
        font-family: var(--fontFamilyFactor);
        &:focus {
          box-shadow: none;
        }
      }
      & > p {
        font-size: 10px;
        color: #000000;
        margin-top: 15px;
        line-height: 15px;
        font-weight: 400;
        font-family: var(--fontFamilyHelvetica);
        /* @media (max-width: 991px) {
          line-height: 12px;
        } */
        @media (max-width: 767px) {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
    & > div:nth-child(2) {
      flex-basis: 30%;
      & > button {
        background-color: #000;
        color: #fff;
        padding: 10px 30px;
        border-radius: 100px;
        font-size: 16px;
        cursor: pointer;
        margin-top: 11px;
        display: inline-block;
        border: none;

        @media (max-width: 575px) {
          width: 100%;
          font-size: 12px;
        }
      }
    }
  }
  @media (max-width: 991px) {
    flex-direction: column;
  }
  @media (min-width: 1920px) {
    max-width: 1440px;
    margin: 0 auto;
  }
}
.bottomContent {
  display: flex;
  & > div:nth-child(1) {
    flex-basis: 70%;
    & > img {
      width: 360px;
      height: auto;
      @media (max-width: 991px) {
        width: 100%;
      }
    }
    & > span {
      display: none;
      @media (max-width: 991px) {
        display: block;
        font-weight: 400;
        font-size: 12px;
        color: #000;
        margin-top: 20px;
      }
    }
    @media (max-width: 1199px) {
      flex-basis: 60%;
    }
  }

  & > div:nth-child(2) {
    flex-basis: 30%;
    display: flex;
    justify-content: space-between;
    & span {
      & > a{
        font-size: 12px;
      color: #000000;
      cursor: pointer;
      font-weight: 400;
      &:hover {
        text-decoration: none;
      }
      }
      
    }
    @media (max-width: 1199px) {
      flex-basis: 40%;
    }
    @media (max-width: 991px) {
      flex-direction: column;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 991px) {
    flex-direction: column-reverse;
    padding: 30px 0;
  }
  @media (max-width: 575px) {
    flex-direction: column-reverse;
    padding: 30px 0;
  }
  @media (min-width: 1920px) {
    max-width: 1440px;
    margin: 0 auto;
  }
}
.footerContainer {
  padding: 35px;
  width: 100%;
  max-width: 100vw;
  @media (min-width: 1920px) {
    max-width: 1440px;
    margin: 0 auto;
  }
  @media (max-width: 991px) {
    padding: 25px;
  }
  @media (max-width: 575px) {
    padding: 15px;
  }
}

.ContactUsmodal {
  & > div {
    background-color: rgb(0, 0, 0, 0.9) !important;
    & > div {
      padding: 25px 15px !important;
    }
  }
}
