.connectDiv {
    flex-basis: 30%;
    display: flex;
    @media(max-width:1199px){
     flex-basis: 40%;
    }
    @media (max-width: 991px) {
      flex-direction: column;
      padding: 25px 0;
      border-top: 1px solid #e4e4e4;
      border-bottom: 1px solid #e4e4e4;
    }

    & > div:nth-child(1) {
      flex-basis: 70%;
      & > span {
        font-size: 16px;
        color: #000000;
        margin: 0;
        font-weight: 500;
      }
      & > label {
        font-size: 12px;
        color: #000000;
        margin-top: 19px;
        padding: 0;
        font-weight:400;
        font-family:var(--fontFamilyHelvetica);
      }
      & > p {
        font-size: 10px;
        color: #000000;
        margin-top: 15px;
        line-height: 15px;
        font-weight:400;
        font-family:var(--fontFamilyHelvetica);
        /* @media (max-width: 991px) {
          line-height: 12px;
        } */
        @media(max-width:767px){
          font-size: 12px;
          line-height:18px;
        }
      }
    }
    & > div:nth-child(2) {
      flex-basis: 30%;
      & > button {
        background-color: #000;
        color: #fff;
        padding: 10px 30px;
        border-radius: 100px;
        font-size: 16px;
        cursor: pointer;
        margin-top: 11px;
        display: inline-block;
        border: none;

        @media (max-width: 575px) {
          width: 100%;
          font-size: 12px;
        }
        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }
  }
  .carouselModalScrollLayer{
    display:flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.9);
    background-image: none;
    height:100vh;
    & > div{
      @media(max-width:767px){
        padding:25px 15px !important;
        
      }
      & > div{
        text-align:center;
        margin-top:40px;
      }
    }
  }
  .formContainer{
    flex-basis: 30%;
    @media(max-width:1199px){
      flex-basis: 40%;
     }
  }
  .emailInput{
    & > input{
      background: transparent !important;
      border: none !important;
      border-bottom: 1px solid #000 !important;
      border-radius: 0 !important;
      padding: 0 !important;
      line-height:30px !important;
      font-weight:400 !important;
      font-family:var(--fontFamilyFactor) !important;
      &:focus {
        box-shadow: none !important;
      }
    }
    
  }