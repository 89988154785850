.bookingTCPage{
 & > span{
  & > span{
    font-family: var(--fontFamilyFactor) !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #000;
  }
 }
}