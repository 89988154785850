.property {
  /* height: max-content;
  display: flex;
  align-items: center;
  justify-content: center; */
  display: flex;
  align-items: center;

  /* background-color: #F9F9F9; */
  background-color: #f5f5f5;
  border-radius: 10px;
  padding:12px;

  text-transform: capitalize;
  color: var(--colorBlack);
  font-size: 16px;
  line-height: 24px;
  @media(max-width:767px){
    font-size:12px;
    line-height: 20px;
    /* padding:0px; */
    padding: 18px;
  }
  
  @media(max-width:575px){
    &>span{
      font-size: 12px;
      line-height: 20px;
    }
  }

  & .icon {
    margin-left: 12px;
    width: 20px;
    height: 20px;
    @media (max-width: 767px) {
      display: none;
    }
  }
  & .mobileIcon {
    margin-right: 12px;
    @media (min-width: 767px) {
      display: none;
    }
    @media (max-width: 767px) {
      width: 30px;
      height: 22px;
    }
    @media (max-width: 575px) {
      width: 28px;
      height: 20px;
    }
  }
}
