.dateTimeRange {
  display: flex;
  color: var(--colorBlack);
  gap: 48px;
  /* justify-content: space-between; */
  /* padding: 8px 20px; */

  & .date,
  & .time {
    display: flex;
    flex-direction: column;
    & >:first-child{
      font-family:var(--fontFamilyFactor);
      font-weight:400;
      font-size:14px;
      line-height:20px;
      color:#000;
      margin-bottom: 10px;
    }
    & > :last-child {
      font-size: 16px;
      line-height: 22px;
      font-weight:500;
      color:#000;
    }
  }
  @media(max-width:767px){
    gap: 28px;
  }
}
