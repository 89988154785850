@import '../../styles/customMediaQueries.css';

.root {
  margin-top: 35px;
}

@media (--viewportMedium) {
  .root {
    /* margin-top: 48px; */
  }
}

.disabled {
  opacity: 0.5;
}

.sectionContainer {
  margin-bottom: 35px;

  @media (--viewportMedium) {
    margin-bottom: 56px;
  }
  & > h3 {
    font-size: 22px;
    line-height: 26.39px;
    color: #000;
    font-weight: 500;
    font-family: var(--fontFamily);
    margin-bottom:25px;

  }
}

.formRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
}

.radioButtonRow {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 24px;
  white-space: nowrap;
  & > span {
    & > input[type='radio'] {
      opacity: 1;
      height: 25px;
      width: 25px;
      border-radius: 2px !important;
      border: 0.5px solid #262626;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      cursor: pointer;
      outline: none;
      position: relative;
      &:checked {
        background-color: #2ecc71;
        border-color: #2ecc71;
        position: relative;
        &::after {
          /* content: '\2713'; */
          font-family: FontAwesome; 
          content: '\f00c'; 
          font-size: 14px; 
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #fff;
        }
      }
      @media(max-width:575px){
        height: 15px;
        width: 15px;
      }
    }
    & > label {
      & > span:nth-child(1) {
        display: none;
      }
      & > span:nth-child(2) {
        margin-left: 15px;
        padding: 0;
        margin-top: -5px;
      }
    }
  }
}

.radioButtonRow > :first-child {
  margin-right: 36px;

} 
.radioButtonRow > span{
  display: flex;
  align-items: flex-start;
  @media(max-width:345px){
    align-items:baseline;
  }
}
.selectCountry {
  margin-bottom: 24px;
  & > select{
    margin-top: 10px;
    box-shadow:none !important;
  }
}

.error {
  composes: marketplaceModalPasswordMargins from global;
}

.termsText {
  composes: marketplaceModalHelperText from global;
  margin-bottom: 12px;
  text-align: center;
  font-family:var(--fontFamilyHelvetica) !important;
  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.termsLink {
  composes: marketplaceModalHelperLink from global;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.bankDetailsStripeField p {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 17px;
    margin-bottom: 15px;
  }
}

.missingStripeKey {
  color: var(--colorFail);
}

.accountInformationTitle {
  margin: 0;
}

.savedCountry {
  padding-top: 6px;
  padding-bottom: 0px;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    padding-top: 4px;
    padding-bottom: 4px;
    margin-bottom: 16px;
  }
}

.savedInformation {
  margin-bottom: 24px;
  & > label{
    color:#000;
  }
}

.savedBankAccount {
  composes: marketplaceInputStyles from global;
  color:#000;
  text-align: left;
  margin-top:10px;
  &:hover {
    cursor: text;
    text-decoration: none;
  }
}
.stripepayoutBtn{
  & > button{
   background-color: #000 !important;
   border-radius:100px;
   &:disabled{
    background-color: var(--colorGrey100) !important;
    color: var(--colorWhite);
   }
  }
}