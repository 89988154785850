.promoNav {
  background-color: #ffbfd6;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  z-index: 99;
  top:0 !important;
}
.shortlogoSvg {
  margin-right: 3px;
}
.linkTxt {
  color: #000000;
  font-size: 14px;
  font-family: "Factor A";
  font-weight:500;
  &:hover {
    text-decoration: none;
  }
}
.rightSvg {
  margin-left: 3px;
}
