@import '../../styles/customMediaQueries.css';

/* Order form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;

  height: 100vh;

  @media (--viewportMedium) {
    flex-basis: 576px;
    height: unset;
    padding: var(--modalPaddingMedium);
    background-color: var(--colorWhite);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  }

  @media (--viewportLarge) {
    padding: 0;
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.modalHeading {
  margin-top: 0;
  margin-bottom: 0px;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
  & > h1 {
    font-size: 32px !important;
    line-height: 36px !important;
    font-weight: 500 !important;
    font-family: var(--fontFamilyFactor) !important;
    text-transform: capitalize;
  }
  @media (max-width: 767px) {
    padding: 0;
  }
}

.heading {
  /* Font */
  color: var(--colorGrey700);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.price {
  composes: h4 from global;
  font-weight: var(--fontWeightBold);

  color: var(--marketplaceColor);
  margin: 0 0 0 24px;
  font-size: 20px;
  line-height: 26px;
  font-family: var(--fontFamilyHelvetica) !important;
  font-weight: 500;
  @media (max-width: 767px) {
    margin: 0;
  }
  @media (--viewportMedium) {
    margin: 0;
    padding: 0;
  }
}

.perUnit {
  font-weight: 500 !important;
  /* Font */
  composes: h4 from global;
  /* font-weight: var(--fontWeightBold); */

  color: var(--marketplaceColor);
  padding: 0;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
  }
}

.author {
  composes: p from global;
  margin: 0px 24px 32px 24px;

  display: flex;
  flex-direction: row;
  align-items: center;

  @media (--viewportMedium) {
    width: 100%;
    margin: 12px 0 36px 0;
    color: var(--colorGrey700);
  }
}

.providerAvatar {
  display: none;

  @media (--viewportLarge) {
    display: flex;
    margin-right: 10px;
  }
}

.providerNamePlain {
  display: inline;

  @media (--viewportLarge) {
    display: none;
  }
}

.providerNameLinked {
  display: none;

  @media (--viewportLarge) {
    display: inline;
  }
}

.orderHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-bottom: 0;
  }
}

.orderTitle {
  /* Font */
  color: var(--colorGrey700);

  margin-top: 0;
  margin-bottom: 2px;

  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

.orderHelp {
  composes: h5 from global;
  display: none;

  @media (--viewportMedium) {
    color: var(--colorGrey700);
    display: block;
    margin-top: 0;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;

  @media (--viewportMedium) {
    padding: 0;
    min-height: 400px;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }
  & > div {
    & > div:nth-child(1) {
      padding-bottom: 15px;
      & > div {
        & > div {
          & > label {
            font-family: var(--fontFamilyFactor) !important;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            @media (max-width: 767px) {
              margin: 0;
            }
          }
          & > div {
            padding-top: 10px;
            & > div {
              & > div {
                & > div {
                  @media (max-width: 767px) {
                    width: 100% !important;
                    margin: 0 !important;
                  }
                  & > div{
                    & > input{
                      &:focus{
                        box-shadow:none !important;
                        border: 1px solid #d8dce6 !important;
                      }
                    }
                  }
                  & > div:nth-child(2) {
                    top: 50px !important;
                    /* background: #fff !important; */
                  }
                }
              }
            }
          }
        }
      }
    }
    & > div:nth-child(2) {
      border-top: 0.5px solid#E4E4E4;
      border-bottom: 0.5px solid#E4E4E4;
      padding: 30px 0 40px;
      flex-wrap: nowrap !important;
      & > div:nth-child(1), & > div:nth-child(3) {
        /* width:50%;
        flex:0 0 50%; */
        & > div {
          & > label {
            font-family: var(--fontFamilyFactor);
            font-size: 14px;
            font-weight: 400;
          }
          & > select {
            margin-top: 10px;
          }
        }
        @media(max-width:420px){
          width:100%;
          flex:0 0 100%;
        }
      }
      @media(max-width:420px){
        flex-wrap: wrap  !important;
      }
    }
  }
}

.openOrderForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 18px 24px 18px 16px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowTop);
  /* display: flex; */
  display: none;
  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  /* transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  }

  @media (--viewportLarge) {
    display: none;
  } */
}

.priceContainerInCTA,
.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 4px;
  flex-shrink: 0;
  margin-right: 22px;
  padding: 3px 0;
  margin-bottom: 20px;
  @media (--viewportMedium) {
    padding: 4px 0;
  }

  @media (--viewportLarge) {
    flex-direction: row;
    margin-right: unset;
    padding: 0;
  }
}
.priceContainerInCTA {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: unset;
}

.priceValue {
  /* Font */
  composes: h3 from global;
  color: var(--marketplaceColor);

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnitInCTA {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightSemiBold);
  padding: 0;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
  }
}

.closedListingButton {
  border-left: 1px solid var(--colorGrey100);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.receipt {
  flex-shrink: 0;
}

.error {
  composes: h4 from global;
  color: var(--colorFail);

  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}
.errorSidebar {
  color: var(--colorFail);
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}
.bookingModal {
  /* @media(max-width:767px){
   position:fixed !important;
   top:0 !important;
   bottom:0 !important;
   left:0 !important;
   right:0 !important;
   height:100vh !important;
  } */
  overflow-x: hidden !important;
  position: absolute !important;
  @media(max-width:767px){
    /* position:fixed !important; */
    top:0 !important;
  }
  & > div {
    height:fit-content;
    & > div {
      flex-basis: 682px !important;
      /* max-width:682px !important; */
      border-bottom: 0 !important;
      /* border-radius: 10px !important; */
      padding: 100px 50px !important;
      & > button {
        @media(max-width:767px){
          background-color: #fff !important;
          width: 100% !important;
          justify-content: flex-end !important;
          box-shadow: 0 0 2px 0px rgb(0, 0, 0, 0.2);
          &:hover {
            background-color: #fff !important;
            box-shadow: 0 0 2px 0px rgb(0, 0, 0, 0.2) !important;
          }
        }

      }
      @media (max-width: 767px) {
        flex-basis: auto !important;
        padding: 100px 15px !important;
        width: 100% !important;
        /* min-height: 100vh !important;
        height: 100% !important; */
      }
    }
  }
}

.carouselModalScrollLayer {
  /* position: fixed !important;
  top: 0 !important;
  left: 0 !important; */
  width: 100vw !important;
  /* height: 100vh !important; */
  background-color: #0006 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.carouselModalContainer {
  display: flex !important;
  justify-content: center !important;
  background-color: #fff !important;
  /* align-items: center !important; */
  width: 100% !important;
  height: max-content !important;
  & > button {
    @media (--viewportMedium) {
      /* right:245px; */
    }
    /* & > span {
      color: var(--colorWhite);
    } */
  }
  & > div {
    width: 100%;
    /* margin-top: 200px; */
    /* height: 400px; */
    @media (max-width: 767px) {
      width: 100%;
      /* height:100vh; */
    }
  }
}

