.property {
  /* height: max-content;
    display: flex;
    align-items: center;
    justify-content: center; */
  display: flex;
  align-items: center;

  /* background-color: #F9F9F9; */
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 16px 28px;

  text-transform: capitalize;
  color: var(--colorBlack);
  font-size: 16px;
  line-height: 24px;

  & .icon {
    margin-left: 12px;
    width: 20px;
    height: 20px;
    & path {
      fill: none;
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
  & .mobileIcon {
    margin-right: 12px;
    & path {
      fill: none;
    }
    @media (min-width: 767px) {
      display: none;
    }
    @media (max-width: 767px) {
      width: 30px !important;
      height: 22px !important;
      display: block;
    }
    @media(max-width:575px) {
      width: 25px;
      height: 20px;
      margin-right: 12px
    }
  }
}
