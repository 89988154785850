.root {
  /* Adding empty .root class to enforce className overwrite */
}

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
  @media(max-width:767px){
    font-size: 24px;
    line-height: 32px;
  }
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
  @media(max-width:767px){
    font-size: 14px;
    line-height: 24px;
  }
}

/* Make the email pop */
.email {
  font-weight: var(--fontWeightHighlightEmail);
  @media(max-width:767px){
    font-size: 14px;
    line-height: 24px;
  }
}

.helperLink {
  composes: a from global;
  composes: marketplaceModalHelperLink from global;
}

.helperText {
  composes: marketplaceModalHelperText from global;
}

.error {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

.modalIcon {
  composes: marketplaceModalIconStyles from global;
}

.reminderModalLinkButton {
  composes: buttonPrimary from global;
}
