.contactPage{
  @media(max-width:767px){
   display: block !important;
  }
 }
 .containerClass {
  margin-top: 130px;
  flex-grow: 1;
  flex-shrink: 0;

  /* This container uses flexbox layout */
  display: flex;
  /* flex-direction: column; */
  width: 100%;

  /* When side nav is shown as a column, Topbar's box-shadow should be visible */
  z-index: calc(var(--zIndexTopbar) - 1);
  @media (max-width: 767px) {
    margin-top: 110px;
  }

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: center;
  }
  @media (min-width: 1920px) {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
  }
  & > main {
    @media (max-width: 1199px) {
      /* padding: 24px 15px; */
    }
  }
}
.sideNavClass {
  display: flex;
  justify-content: flex-start !important;
  @media (max-width: 1023px) {
    width: 100%;
    padding: 0 15px;
  }
  & > nav{
   @media(max-width:1023px){
    justify-content:flex-start;
   }
  }
}
.desktopTopbar,
.mobileTopbar {
  box-shadow: none;
}
.thnkdv {
  min-height: 59vh;
  padding: 30px 0;
  text-align: center;
  & > h1{
    font-family: Factor A;
    font-size: 45px;
    font-weight: 500;
    line-height: 52px;
    margin-bottom: 0;
    color:#000;
  }
}
.formdiv {
  margin: 0 0 50px;
  & button {
    width: 200px;
    color: #fff;
    background-color: #000;
    border: 0;
    border-radius: 10px;
    min-height: 45px;
    cursor: pointer;
    font-family: 'Helvetica Neue';
    &:hover {
      background-color: var(--marketplaceColorDark);
    }
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  & input {
    border: 1px solid #cecece;
    padding: 5px 10px;
    border-radius: 10px;
    &:focus {
      border: 1px solid black;
    }
  }
  @media(max-width:1023px){
    margin:0 0 50px;
  }
  @media(max-width:991px){
    margin:20px 0 50px;
  }
  @media(max-width:767px){
    margin:20px 0;
  }
  & > h1{
    font-size: 25px;
    font-weight: 400;
    margin:0;
    @media(max-width:767px){
      margin-bottom:15px;
    }
  }
}
.emaildv {
  margin: 0 0 15px;
}
.msgdv {
  margin: 0 0 15px;
}
.message {
  & textarea {
    height: 180px !important;
    padding: 5px 10px;
    box-sizing: border-box;
    border: 1px solid #cecece;
    border-radius:10px;
    &:focus {
      border: 1px solid black;
    }
  }
}
.gohome {
  width: 200px;
  color: #fff;
  background-color: var(--marketplaceColor);
  border: 0;
  border-radius: 10px;
  min-height: 45px;
  margin-top: 50px;
  cursor: pointer;
  &:hover {
    background-color: var(--marketplaceColorDark);
  }
}