.property {
  /* height: max-content;
    display: flex;
    align-items: center;
    justify-content: center; */
  display: flex;
  align-items: center;

  /* background-color: #F9F9F9; */
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 16px 28px;

  text-transform: capitalize;
  color: var(--colorBlack);
  font-size: 16px;
  line-height: 24px;
}
