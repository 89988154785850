@import '../../styles/customMediaQueries.css';

.root {
}

.pageRoot {
  padding-bottom: 90px;

  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--colorFail);
  margin: 24px;
}

.sectionHero {
  position: relative;
  margin: 0 0 36px 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.heroHeight {
  @media (--viewportMedium) {
    height: calc(0.41 * 100vw);
    max-height: 50vh;
    overflow-y: hidden;
  }
}

.imageWrapperForSectionHero {
  composes: heroHeight;
  background-color: var(--colorGrey100); /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;
}

.actionBar {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  color: var(--colorGrey100);
  background-color: var(--colorGrey700);
  z-index: 1; /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);

  @media (--viewportMedium) {
    flex-direction: row;
  }
}
/* .desktopTopbar {
  @media (max-width: 767px) {
    display: none;
  }
}
.mobileTopbar {
  @media (min-width: 767px) {
    display: none;
  }
} */

.actionBarForProductLayout {
  margin: 24px;
  width: calc(100% - 48px);

  @media (--viewportMedium) {
    margin: 24px 0 24px 0;
    width: 100%;
  }

  @media (--viewportLarge) {
    margin: 0 0 24px 0;
  }
}

.actionBarContainerForHeroLayout {
  position: absolute;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.actionBarForHeroLayout {
  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;
}

.ownListingText {
  composes: h5 from global;
  margin: 14px 24px 11px 24px;

  @media (--viewportMedium) {
    margin: 24px 12px 24px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--colorAttention);
}

.closedListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.addPayoutDetails,
.editListingLink {
  composes: h5 from global;
  flex-shrink: 0;
  margin: 14px 24px 11px 24px;
  color: var(--colorGrey100);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorWhite);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 24px 24px 24px 12px;
  }
}

.missingPayoutDetailsText {
  color: var(--colorAttention);
}

.payoutDetailsWarning {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  composes: heroHeight;
  width: 100%;
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--colorWhite);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

/* .carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
} */

.contentWrapperForHeroLayout {
  @media (--viewportMedium) {
    width: 100%;
    max-width: none;
    /* max-width: var(--contentMaxWidth); */
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    /* max-width: calc(var(--contentMaxWidth) + 72px); */
    padding: 0 36px;
    margin: 0 auto 117px;
  }

  @media (--viewportXLarge) {
    /* max-width: var(--contentMaxWidth); */
    padding: 0;
  }
}

.mainColumnForProductLayout {
  width: 50%;
  flex: 0 0 50%;
  display: flex;
  align-items: center;
  padding-right: 100px;
  @media (max-width: 991px) {
    padding-right: 50px;
  }
  @media (max-width: 767px) {
    display: none;
  }
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    /* contentContainer's full width - (orderColumn + paddings + margin) */
    /* max-width: calc(100% - 400px); */
    /* flex-basis: calc(100% - 360px); */
    /* flex-basis: calc(100% - 450px);
    flex-shrink: 0;
    flex-grow: 1; */
  }
}

.orderColumnForProductLayout {
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    display: block;
    margin-left: 48px;
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.contentWrapperForProductLayout {
  /* margin: 0 0 56px 0; */
  display: flex;
  display: flex;
  max-width: none;
  width: 100%;
  padding: 0 80px;
  margin-top: 180px;
  @media (min-width: 1920px) {
    max-width: 1440px;
    margin: 180px auto 0;
  }
  @media (max-width: 991px) {
    padding: 0 20px;
  }
  @media (max-width: 767px) {
    padding: 0px;
    margin-top: 0px;
    @media (max-width: 767px) {
      padding: 0px;
      margin-top: 0px;
    }
    @media (--viewportMedium) {
      /* width: 100%; */
      /* max-width: var(--contentMaxWidth); */
      /* padding: 0 24px 0 24px;
    margin: 0 auto 56px auto; */
    }

    @media (--viewportLarge) {
      /* display: flex;
    flex-direction: row; */
      /* max-width: none; */
      /* width: min(calc(var(--contentMaxWidth) + 72px), 100vw); */
      /* padding: 64px 36px 0 36px;
      margin: 0 auto 117px auto; */
      /* margin: 0 auto 17px auto; */
    }
  }
}

.mainColumnForHeroLayout {
  flex-basis: 100%;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 80px;
    margin-bottom: 0px;
    /* contentContainer's full width - (orderColumn + two paddings + margin + border) */
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.orderColumnForHeroLayout {
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    display: block;
    margin-top: 80px;
    margin-left: 60px;
    border-left: 1px solid var(--colorGrey100);
    padding-left: 60px;
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.orderPanel {
  /* Note: panel height might be too much on small viewport - hence min-height */
  @media (--viewportLarge) and (min-height: 950px) {
    position: sticky;
    top: 108px;
  }
}

.productOrderPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;
  }
  /* Note: panel height might be too much on small viewport - hence min-height */
  @media (--viewportLarge) and (min-height: 950px) {
    position: sticky;
    top: 108px;
  }
}

.orderPanelTitle {
  /* Font */
  font-weight: var(--fontWeightBold);
  margin-bottom: 5px !important;
  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 32px !important;
  line-height: 36px !important;
  font-weight: 500 !important;
  font-family: var(--fontFamilyFactor) !important;
  text-transform: capitalize;
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flexbox would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -49px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */

  @media (--viewportMedium) {
    display: flex;
  }
}

.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  composes: h5 from global;
  color: var(--colorGrey700);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--colorGrey700);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.author {
  width: 100%;
  composes: h5 from global;

  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--colorGrey700);
  }
}

.authorNameLink {
  composes: textSmall from global;
  color: var(--colorGrey700);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.sectionText {
  padding: 0 24px;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}

.sectionHeading,
.sectionHeadingWithExtraMargin {
  /* Font for sections titles */
  composes: p from global;
  padding: 0;
  font-weight: var(--fontWeightBold);

  margin-top: 0;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
  }
}

.sectionHeadingWithExtraMargin {
  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.text {
  font-weight: var(--fontWeightRegular);
  padding: 1px 0 5px 0;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    padding: 3px 0 5px 0;
    margin: 0;
  }
}

.ingress {
  composes: p from global;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.sectionDetails,
.sectionMultiEnum {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.sectionReviews,
.sectionAuthor {
  padding: 0 24px;
  margin-top: 42px;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 40px;
  }
  @media (--viewportLarge) {
    padding: 8px 0;
  }
}

.details {
  padding-bottom: 1px;
  margin: 0;
}

.detailsRow {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding: 1px 0 4px 0;
  line-height: 36px;
  list-style: none;
  font-weight: var(--fontWeightRegular);

  &:not(:last-child) {
    border-bottom: 1px solid var(--colorGrey100);
  }

  @media (--viewportMedium) {
    padding: 5px 0 2px 0;
    line-height: 32px;
  }
}

.detailLabel {
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.09px;
}

.inquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.inquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.inquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--colorWhite);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
    margin-bottom: 7px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.productGallery {
  margin: 0 0 36px 0;

  @media (--viewportMedium) {
    margin: 24px 0 48px 0;
  }
  @media (--viewportLarge) {
    margin: 0 0 40px 0;
    padding-bottom: 4px;
  }
}

.mobileHeading {
  margin: 0 24px 24px 24px;

  @media (--viewportMedium) {
    margin: 0 0 24px 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.productDesktopHeading {
  display: none;
  @media (--viewportLarge) {
    display: block;
  }
}

.productSideContent {
}

.productBookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}
.priceContainerInCTA,
.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 4px;
  flex-shrink: 0;
  margin-right: 22px;
  padding: 3px 0;

  @media (--viewportMedium) {
    padding: 4px 0;
  }

  @media (--viewportLarge) {
    flex-direction: row;
    margin-right: unset;
    padding: 0;
  }
}
.priceContainerInCTA {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: unset;
}

.priceValue {
  /* Font */
  composes: h3 from global;
  color: var(--marketplaceColor);

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnitInCTA {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightSemiBold);
  padding: 0;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
  }
}
.infoDiv {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: flex-start;
  width: 100%;
  gap: 15px;
  /* background-color: green; */
  /* margin-top: 150px; */
  padding: 30px 0;
  & div:nth-child(1) {
    color: #000000;
    font-family: 'Factor A';
    font-weight: 500;
    font-size: 48px;
    line-height: 52.78px;
    @media (max-width: 991px) {
      font-size: 40px;
    }
  }
  & div:nth-child(2) {
    color: #000000;
    font-family: 'Factor A';
    font-weight: 400;
    font-size: 20px;
  }
  & div:nth-child(3) {
    color: #000000;
    font-family: 'Factor A';
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
  & div:nth-child(4) {
    color: #000000;
    font-family: 'Helvetica Neue';
    font-size: 32px;
    font-weight: 400;
  }
  & div:nth-child(5) {
    font-family: 'Helvetica Neue';
    font-weight: 400 !important;
    font-size: 14px;
    color: #bdbdbd;
  }
  & div:nth-child(6) {
    margin-top: 5px;
    & > span {
      display: inline-block;
      & > svg {
        cursor: pointer;
        width: 18px !important;
        height: 24px !important;
        & > path {
          fill: none;
        }
      }
    }
  }
}
.bookingDiv {
  /* width: min(calc(var(--contentMaxWidth) + 72px), 100vw); */
  /* padding: 0px 36px 0 36px; */
  /* padding: 64px 36px 0 36px; */
  /* margin: 0 auto 117px auto; */
  width: 100%;
  padding: 0 80px;
  overflow: hidden;
  /* @media(max-width:767px){
    padding: 0 20px;
  } */
  @media (min-width: 1920px) {
    max-width: 1440px;
    margin: 0 auto;
  }
  @media (max-width: 991px) {
    padding: 0 20px;
  }
  @media (max-width: 767px) {
    padding: 0 15px;
  }
}
.service {
  font-family: 'Factor A';
  font-size: 20px;
  font-weight: 400;
  color: #bdbdbd;
  margin-top: 100px;
  @media (max-width: 991px) {
    margin-top: 50px;
  }
  @media (max-width: 575px) {
    font-size: 14px;
  }
  @media (max-width: 767px) {
    margin-top: 40px;
  }
}
.events {
  font-family: 'Factor A';
  font-size: 20px;
  font-weight: 400;
  color: #bdbdbd;
  margin-top: 65px;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
  @media (max-width: 575px) {
    font-size: 14px;
  }
  @media (max-width: 767px) {
    margin-top: 40px;
  }
}
.Line {
  width: 100%;
  border: 0.5px solid #bdbdbd;
  margin-top: 10px;
}
.serviceCard {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  /* border: 1px solid; */
  /* width: 320px; */
  /* min-height: 400px;
  height: max-content; */
  margin: 38px 0;
  padding: 10px 0;
  gap: 20px;
  /* margin-right: 80px; */
  flex: 0 0 auto;
  width: 30.33%;
  min-height: 420px;
  @media (max-width: 991px) {
    width: 50%;
  }
  @media (max-width: 767px) {
    width: 100%;
    height: max-content;
    margin: 0;
    margin-top: 60px;
    padding: 30px;
    background-color: #f5f5f5;
    border-radius: 10px;
    min-height: 0px;
  }
  @media (max-width: 575px) {
    margin-top: 25px;
    padding: 15px;
    gap: 10px;
  }
}
.serviceName {
  text-transform: capitalize;
  font-family: 'Factor A';
  font-size: 32px;
  font-weight: 500;
  color: #000000;
  line-height: 38px;
  @media (max-width: 767px) {
    font-size: 26px;
  }
  @media (max-width: 575px) {
    font-size: 20px;
  }
}
.propertyDIv {
  width: 100%;
  /* margin-top: auto; */
  /* flex-basis: 100px; */
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  gap: 15px;
  @media (max-width: 767px) {
    gap: 30px;
    margin-top: 10px;
  }
  & div:nth-child(1) {
    text-transform: none;
  }
  /* & div:not(:last-child){
    border-right: 1px solid black;
  } */
  .box:nth-child(2n + 1) {
    border-bottom: 1px solid black;
  }
}
.durationProperty,
.property {
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  /* background-color: #F9F9F9; */
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 12px;

  text-transform: capitalize;
  & > span {
    font-family: 'Helvetica Neue';
    font-size: 14px;
    font-weight: 500;
    color: #000000;
  }
  @media (max-width: 767px) {
    padding: 0px;
    & > span {
      font-size: 12px;
    }
  }
  @media (max-width: 575px) {
    & > span {
      font-size: 12px;
      line-height: 16px;
    }
  }

  /* margin: 5px 0px; */
}
.durationProperty {
  & > svg {
    fill: none;
    stroke: black;
  }
}
.desc {
  min-height: 50px;
  font-family: 'Factor A';
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  @media (max-width: 767px) {
  }
  @media (max-width: 575px) {
    font-size: 12px;
    min-height: max-content;
    line-height: 20px;
  }
}
.btnDiv {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: auto;
  font-size: 16px;
  @media (max-width: 767px) {
    font-size: 12px;
  }
  @media (max-width: 767px) {
    margin-top: 30px;
    & > button {
      width: 100%;
    }
  }
}
.bookBtn {
  cursor: pointer;
  border: 0;
  background-color: #fdb8cf;
  width: 130px;
  padding: 15px;
  border-radius: 100px;
  color: #000000;
  font-size: 16px;
  @media (max-width: 575px) {
    font-size: 12px;
    padding: 12px;
  }
  &:disabled {
    cursor: not-allowed;
    color: rgb(241, 240, 240);
    background-color: rgb(206, 205, 205);
  }
}
.eventBookBtn {
  @media (--viewportLargeWithPaddings) {
    width: 240px;
  }
  @media (max-width: 767px) {
    width: 130px;
  }
  @media (max-width: 576px) {
    /* width:130px; */
  }
}
.serviceWrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  /* align-items: center; */
  /* justify-content: space-between; */
  /* gap: 25px; */
}
/* .communityFavourites {
  display: flex;
  flex-direction: row;
  margin-top: 0px; */

/* @media (--viewportMedium) {
    flex-direction: row;
    margin-top: 0px;
  } */
/* @media (max-width: 767px) {
    & > div {
      & > div:nth-child(2) {
        & > div { 
          & > div {
            min-width: 320px;
            @media (max-width: 991px) {
              min-width: 283px;
            }
          }
        }
      }
    }
  } */

/* & > div {
    & > svg {
      fill: none;
    }
    & > div { */
/* padding: 0 5px 0 15px;
    margin-left: -15px; */
/* margin-right: -5px;
      padding: 0 15px;
      margin-left: -10px;
      @media (max-width: 575px) {
        margin-right: -10px;
      }
    }
  }
} */
.contentDiv {
  display: flex;
  gap: 20px;
  width: 800px;
  height: 730px;
  /* @media(max-width:991px){
    width:100%;
  } */
  /* background-color: red; */
}
/* .searchLink {
  width: 100%;
  margin-top: 25px;
  object-fit: contain;
  padding: 0 15px 0 0;
  min-width: auto;
  padding-right:15px;
  cursor:pointer;
  &:hover {
    text-decoration: none;
  } */
/* background: #fff;
  padding: 15px 15px 30px;
  border-radius: 10px;
  margin: 15px 5px; */
/* @media(min-width:1200px) and (max-width:1300px){
    min-width: 278px;
  }
  @media(min-width:1400px){
    min-width: 339px;
  }
  @media(min-width:1600px){
    min-width: 380px;
  }
  @media(min-width:1920px){
    min-width: 339px;
  } */
/* } */
.imgDiv {
  width: 50%;
  border-radius: 10px;
  /* background-color: black; */
}
.motivationalQuote {
  padding: 30px 20px;
  /* width: 50%; */
  height: 415px;
  border-radius: 10px;
  background-color: #00a661;
  /* margin-right: 10px; */
  @media (max-width: 575px) {
    & > p {
      font-size: 12px;
    }
    & > div {
      font-size: 16px;
    }
  }
  & > p {
    font-family: 'Factor A';
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    @media (max-width: 575px) {
      font-size: 17px;
      margin-bottom: 0px !important;
    }
  }
  & > div {
    font-family: 'Factor A';
    font-size: 25px;
    font-weight: 400;
    color: #ffffff;
    line-height: 34px;
    max-height: 200px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    @media (max-width: 575px) {
      font-size: 14px;
      line-height: 26px;
    }
  }
}
.ListingImage {
  /* min-width: 600px; */
  width: 50%;
  flex: 0 0 50%;
  height: 500px;
  overflow: hidden;
  position: relative;
  /* background-color: red; */
  & > img {
    border-radius: 10px;
    width: 100%;
    max-height: 500px;
    height: 100%;
    object-fit: contain;
    object-position: center;
    position: absolute;
  }
  @media (max-width: 767px) {
    position: relative;
    max-height: 400px;
    width: 100%;
    flex: 0 0 100%;
  }
}
.muteButton {
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: white;
  cursor: pointer;
  & > svg {
    width: 25px;
    height: 25px;
    /* & > path{
      fill:#fff;
      stroke:#fff;
    } */
    @media (max-width: 767px) {
      width: 22px;
      height: 22px;
    }
  }
  @media (max-width: 767px) {
    bottom: 8px;
    right: 8px;
  }
}
.previewVideo {
  border-radius: 10px;
  width: 100%;
  max-height: 500px;
  object-fit: cover;
  height: 500px;
  object-fit: contain;
  object-position: center;
  position: absolute;
  @media (max-width: 767px) {
    max-height: 400px;
    height: 400px;
  }
}
.PersonSvg {
  & path {
    fill: none;
  }
}
.avatar {
  margin: 5px 26px 0px 0;
  flex-shrink: 0;
  height: 40px;
  width: 30px;
  /* @media(max-width:767px){
    margin: 5px 26px 0px 0;
  } */
  /* @media (--viewportLarge) {
    margin: 0 96px 48px 0;
  } */
  @media (max-width: 991px) {
    margin: 0;
  }
}
.reviewCard {
  width: 100%;
  /* background-color: #00a661; */
  padding: 20px;
  padding-right: 0px !important;
  display: flex !important;
  align-items: flex-start;
  justify-content: space-between;
  height: auto;
  /* gap: 20px; */
  /* border-bottom: 0.5px solid #bdbdbd; */
  margin: 20px 0;
  @media (max-width: 991px) {
    border-bottom: none;
    margin-bottom: 0px;
    padding-bottom: 0;
    padding-left: 0;
  }
  @media (max-width: 767px) {
    padding: 0;
  }
  /* @media(min-width:767px){
    display:none
  } */
  & div:nth-child(1) {
    /* background-color: red; */
    /* padding: 10px; */
    /* width: 10%; */
  }
  & div:nth-child(2) {
    /* background-color: red; */
    /* padding: 10px; */
    /* width: 30%; */
  }
  & div:nth-child(3) {
    /* background-color: red; */
    /* padding: 10px; */
    /* width: 40%; */
  }
  & div:nth-child(4) {
    /* background-color: red; */
    /* padding: 10px; */
    /* width: 10%; */
  }
}
.workout {
  & > span {
    font-family: 'Helvetica Neue';
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    @media (max-width: 575px) {
      font-size: 12px;
      line-height: 20px;
    }
  }
  @media (max-width: 767px) {
    font-size: 12px !important;
    line-height: 20px !important;
    padding: 0px !important;
  }

  @media (max-width: 575px) {
    & > span {
      font-size: 10px !important;
      line-height: 8px !important;
    }
  }

  /* line-height: 17.09px;
text-align: left; */
}
.reviewContents {
  display: flex;
  gap: 60px;
}
.reviewDiv {
  max-width: 600px;
  /* margin-left: 100px; */
  @media (max-width: 991px) {
    display: none;
  }
  & > p:nth-child(1) {
    font-family: 'Factor A';
    font-size: 20px;
    font-weight: 500;
    color: #000000;
    margin-top: 0px !important;
  }
  & > p:nth-child(2) {
    font-family: 'Factor A';
    font-size: 14px;
    font-weight: 400;
    color: #000000;
  }
}
.reviewinfoDiv {
  margin-left: 40px;
  & > p:nth-child(1) {
    font-family: 'Factor A';
    font-size: 20px;
    font-weight: 500;
    color: #000000;
    margin: 0px;
    @media (max-width: 575px) {
      font-size: 14px;
      margin-left: 0;
    }
  }
  & > p:nth-child(2) {
    font-family: 'Factor A';
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    margin: 0px;
    @media (max-width: 575px) {
      font-size: 12px;
    }
  }
  @media (max-width: 991px) {
    margin-left: 20px;
    /* padding-bottom:10px; */
  }
  @media (max-width: 767px) {
    margin-left: 10px;
  }
}
.img {
  & > div {
    /* width: 100%; */
    width: 40px;
    height: 40px;
    min-width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 767px) {
      margin: 0;
    }
  }
}
.listingMobileTitle {
  display: none;
  position: absolute;
  bottom: 40px;
  left: 30px;
  /* @media (min-width: 767px) {
    display: none;
  } */
  @media (max-width: 767px) {
    display: block;
    bottom: 15px;
    left: 15px;
  }
}
.mobileTitle {
  color: #fff;
  font-family: 'Factor A';
  font-weight: 500;
  font-size: 30px;
  margin-bottom: 20px;
  @media (max-width: 575px) {
    margin-bottom: 10px;
    font-size: 25px;
  }
}
.mobilePersonal {
  color: #fff;
  font-family: 'Factor A';
  font-weight: 400;
  font-size: 20px;
  @media (max-width: 575px) {
    font-size: 14px;
  }
}
.mobileShare {
  display: none;
  position: absolute;
  bottom: 40px;
  right: 30px;
  & > span {
    & > svg {
      cursor: pointer;
      /* color:#fff; */
      border: #fff;
      width: 20px;
      height: 20px;
      & > path {
        fill: none;
        stroke: #fff;
        /* color:#fff; */
      }
    }
  }
  & > div {
    @media (max-width: 767px) {
      left: unset !important;
      right: 0 !important;
      width: 300px !important;
    }
  }
  @media (max-width: 767px) {
    display: block;
    bottom: 15px;
    right: 50px;
    & > svg {
      width: 18px;
      height: 18px;
    }
  }
}
.priceRangeMobile {
  color: #000000;
  font-family: var(--fontFamilyHelvetica);
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 30px;
  @media (max-width: 767px) {
    font-size: 20px;
    margin-bottom: 20px;
    font-family: var(--fontFamilyFactor);
  }
  @media (max-width: 575px) {
    margin-bottom: 18px;
  }
}
.mobileInformation {
  margin-top: 50px;
  padding: 0px 40px;
  @media (max-width: 767px) {
    margin-top: 40px;
    padding: 0px 15px;
  }
  & > div:nth-child(1) {
  }
  & > div:nth-child(2) {
    font-family: 'Helvetica Neue';
    font-weight: 400;
    font-size: 14px;
    color: #bdbdbd;
    margin-bottom: 30px;
    @media (max-width: 767px) {
      font-size: 14px;
      margin-bottom: 20px;
    }
    @media (max-width: 575px) {
      margin-bottom: 12px;
    }
  }
  & div:nth-child(3) {
    color: #000000;
    font-family: 'Factor A';
    font-weight: 400;
    font-size: 16px;
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }

  @media (min-width: 767px) {
    display: none;
  }
}
.mobileReviewDiv {
  /* max-width:600px; */
  margin-left: 15px;
  @media (max-width: 991px) {
    margin-left: 0;
  }
  @media (min-width: 991px) {
    display: none;
  }
  & > p:nth-child(1) {
    font-family: 'Factor A';
    font-size: 20px;
    font-weight: 500;
    color: #000000;
    margin-bottom: 5px;
    @media (max-width: 575px) {
      font-size: 14px;
    }
  }
  & > p:nth-child(2) {
    font-family: 'Factor A';
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    margin-top: 0;
    margin-bottom: 0;
    @media (max-width: 575px) {
      font-size: 12px;
    }
  }
  @media (max-width: 767px) {
    margin-left: 0;
  }
}
.ratingDiv {
  & > p:nth-child(1) {
    font-family: 'Factor A';
    font-size: 20px;
    font-weight: 500;
    color: #000000;
    margin-top: 0px !important;
  }
}
.information {
  display: flex;
}
.shareContainer {
  position: relative;
}
.shareDiv {
  display: flex;
  position: absolute;
  /* left: -8px !important; */
  background: #fff;
  border-radius: 10px;
  z-index: 5;
  padding: 20px;
  padding-bottom: 10px;
  /* border: 1px solid black; */
  filter: drop-shadow(0 2px 5px #0004);
  margin-top: 15px;
  width: max-content;
  font-family: 'Helvetica Neue' !important;
  font-size: 18px !important;
  & > div {
    & > button {
      margin-right: 10px;
    }
  }
  /* left: 0 !important; */
  /* @media (max-width: 1023px) {
    z-index: 1;
  } */
  @media (max-width: 575px) {
    /* right: 10%; */
    margin-top: 10px;
    /* left: 0 !important; */
  }

  &:before {
    left: 23px;
    width: 1.5em;
    border: 0.75em solid transparent;
    bottom: 100%;
    height: 1.5em;
    content: '';
    z-index: -1;
    position: absolute;
    transform: scaleY(0.6) translateX(-50%);
    box-sizing: border-box;
    pointer-events: none;
    transform-origin: 100% 100%;
    border-bottom-color: white;
    @media (max-width: 767px) {
      left: unset !important;
      right: -3px !important;
      bottom: 99% !important;
    }
  }
}
.linkCopyShare {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width:100%; */

  & input {
    border: 1px solid #c7c7c7 !important;
    padding: 0 2px !important;
  }
  & button {
    /* padding: 3px 0 !important; */
  }

  & button {
    /* flex-basis: 35%; */
    /* width:100%; */
    display: flex;
    justify-content: center;
    width: 48px;
    height: 48px;
    align-items: center;
    background: #e2e2e2;
    /* padding: 10px 0px; */
    border-radius: 50px !important;
    border: none !important;
    box-shadow: none;
    color: #fff;
    font-size: 14px;
    font-family: 'Helvetica Neue';
    font-weight: 400 !important;
    cursor: pointer;
    opacity: 1;
    & > svg {
      fill: #000 !important;
    }
  }
  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
}
.linkCopyBox {
  /* display:none; */
  position: absolute;
  opacity: 0;
  left: -9999px;
  flex-basis: 65%;
  text-overflow: ellipsis;
  border: 1px solid #c7c7c7;
  border-radius: 6px 0 0 6px;
  /* box-shadow: none !important; */
  margin: 10px 0;
  padding: 3px 0;
  font-size: 14px;
  font-family: 'Helvetica Neue';
  @media (max-width: 575px) {
    padding: 7px 0;
    flex-basis: 100%;
  }
}
.shareButton {
  width: 100%;
  font-size: 24px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.editButton {
  width: max-content;
  & > a {
    & > svg {
      color: #000;
      width: 24px;
      height: 24px;
    }
  }
  @media (max-width: 767px) {
    & > a {
      & > svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
.mobileFirstDiv {
  display: flex;
  justify-content: space-between;
}
.noReview {
  margin: 20px 0;
}
.shareIconDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  @media (max-width: 767px) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
.iconName {
  font-size: 12px !important;
  line-height: 35px !important;
}
.buttonWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  & > button {
    min-height: 45px;
    & > svg {
      /* fill:#fff !important */
    }
  }
}
.hoverDiv {
  display: flex;
  position: absolute;
  /* left: -8px !important; */
  background: #fff;
  border-radius: 10px;
  z-index: 5;
  padding: 10px;
  /* border: 1px solid black; */
  filter: drop-shadow(0 2px 5px #0004);
  margin-top: 15px;
  /* width: 300px; */
  font-family: 'Helvetica Neue' !important;
  font-size: 18px !important;
  & > div {
    & > button {
      margin-right: 10px;
    }
  }
  /* left: 0 !important; */
  /* @media (max-width: 1023px) {
    z-index: 1;
  } */
  @media (max-width: 575px) {
    /* right: 10%; */
    margin-top: 10px;
    /* left: 0 !important; */
  }
}

.eventWrapper {
  /* background-color: #F9F9F9;
  width:100%;
  height:400px;
  display: flex;
  padding:30px;
  margin:38px 0px; */
}
.eachWrapper {
  width: 100% !important;
  min-height: 550px;
  display: flex !important;
  padding: 30px;
  margin: 38px 0px;
  background-color: #f5f5f5;
  @media (max-width: 767px) {
    height: max-content;
    display: block !important;
    min-height: 550px;
  }
}
.eventImage {
  width: 30%;
  flex: 0 0 30%;
  border-radius: 10px;
  cursor: pointer;
  /* height: 500px; */
  @media (max-width: 991px) {
    height: 540px;
  }
  /* & > img{
    object-fit:cover;
  } */
}
.eventImageForInner {
  width: 30%;
  flex: 0 0 30%;
  border-radius: 10px;
  cursor: pointer;
  /* height: 500px; */
  @media (max-width: 991px) {
    height: 540px;
  }
  /* & > img{
    object-fit:cover;
  } */
  & > div {
    & button:nth-child(1) {
      /* left: 1px;
      right: 1px; */
      left: 10px;
      z-index: 999;
    }
    & button:nth-child(3) {
      right: 10px;
      z-index: 999;
    }
  }
}
.eventDetails {
  /* background-color: #00a661; */
  display: flex;
  width: 70%;
  flex: 0 0 70%;
  margin-left: 50px;
}
.locationInidividual {
  border-right: 0.5px solid #000000;
  min-width: max-content;
  height: 102px;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
  & div:nth-child(2) {
    & > div {
      padding-left: 0px !important;
    }
  }
  @media (max-width: 767px) {
    border-right: none !important;
    display: flex;
    flex-direction: column;
    gap: 10px;
    display: block;
    height: max-content;
    & > div:nth-child(2) {
      margin-top: 10px;
    }
  }
}
.locationContainer {
  display: flex;
  margin-top: 50px;
  @media (max-width: 767px) {
    display: block;
    margin-top: 20px !important;
    border-bottom: 0.5px solid #e4e4e4;
    padding-bottom: 20px;
    height: 131px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.event {
  font-family: var(--fontFamilyFactor);
  font-size: 16px;
  font-weight: 400;
  color: var(--colorBlack);
}
.eventTitle {
  font-family: var(--fontFamilyFactor);
  font-size: 32px;
  font-weight: 500;
  margin-top: 20px;
  color: var(--colorBlack);
  line-height: 38px;
}
.eventDescription {
  margin-top: 20px;
  font-family: var(--fontFamilyFactor);
  font-size: 14px;
  font-weight: 400;
  color: var(--colorBlack);
  max-height: 48px;
  overflow-y: hidden;
  word-break: break-all;
  & p {
    font-weight: 400;
  }
  & ul {
    list-style-type: disc;
    padding-left: 15px;
    margin: 0;
    & li {
      font-size: 14px;
      font-weight: 400 !important;
      padding: 0;
    }
  }
  @media (max-width: 767px) {
    /* border-bottom: 0.5px solid #e4e4e4;
    padding-bottom: 20px; */
  }
}
.fullDet {
  max-height: unset;
  overflow-y: unset;
}
.locationAddress {
  padding: 5px;
  margin-left: 20px;
  font-family: var(--fontFamilyFactor);
  font-weight: 400;
  font-size: 12px;
  line-height: 16.8px;
  width: 232px;
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    margin-left: 0px !important;
    margin-top: 15px;
  }
}
.eventDate {
  font-family: var(--fontFamilyFactor);
  font-size: 12px;
  font-weight: 400;
  color: var(--colorBlack);
  display: flex;
  align-items: center;
  gap: 10px;
}
.dateAndTimeContainer {
  margin-top: 50px;
  @media (max-width: 767px) {
    margin-top: 20px;
    border-bottom: 0.5px solid #e4e4e4;
    padding-bottom: 20px;
  }
}
.eventTime {
  margin-top: 10px;
}
.eventDetailsFirstChild {
  width: 60%;
  flex: 0 0 60%;
  border-right: 1px solid #c8c8c8;
  padding-right: 15px;
}
.eventDetailsSecondChild {
  width: 35%;
  flex: 0 0 35%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.priceFormat {
  display: flex;
  justify-content: flex-end;
  /* & > span{ */
  /* font-family:var(--fontFamilyFactor) !important; */
  font-family: var(--fontFamilyHelvetica);
  font-size: 32px !important;
  font-weight: 500 !important;
  color: var(--colorBlack) !important;
  /* } */
}
.btnWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.availableTickets {
  margin-top: 10px;
  font-family: var(--fontFamilyFactor);
  font-size: 12px;
  font-weight: 400;
  color: var(--colorBlack);
}
.imagePreview {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}
.ContactUsmodal {
  & > div {
    background-color: rgb(0, 0, 0, 0.9) !important;
    & > div {
      padding: 25px 15px !important;
      overflow: hidden !important;
    }
  }
}
.sliderContainer {
  overflow: hidden;
}
.carouselModalScrollLayer {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100vw !important;
  height: 100vh !important;
  background-color: rgba(32, 31, 31, 0.92) !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.carouselModalContainer {
  display: flex !important;
  justify-content: center !important;
  /* align-items: center !important; */
  width: 100% !important;
  height: max-content !important;
  & > button {
    & > span {
      color: var(--colorWhite);
    }
  }
  & > div {
    width: 90%;
    /* height: 400px; */
    @media (max-width: 767px) {
      width: 100%;
    }
  }
}
.noneOnMobile {
  @media (max-width: 767px) {
    display: none !important;
  }
}
.mobileBtnWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
}
.mobileEventDetails {
  width: 100%;
}
.mobileEventHeader {
  border-bottom: 0.5px solid #e4e4e4;
  padding-bottom: 20px;
  display: flex;
  & > div {
    & button:nth-child(1) {
      /* left: 1px;
      right: 1px; */
      left: 10px;
      z-index: 999;
    }
    & button:nth-child(3) {
      right: 10px;
      z-index: 999;
    }
  }
  @media (max-width: 767px) {
    flex-direction: column-reverse;
    gap: 20px;
    & .mobileImageEventDiv {
      width: 100% !important;
    }
  }
}
.mobileImageEventDiv {
  width: 100px !important;
  height: 300px !important;
}
.mobileEventNames {
  margin-left: 20px;
}
.noneOnDeskTop {
  @media (min-width: 767px) {
    display: none !important;
  }
}
.contentClassName {
  display: flex;
  justify-content: center;
  align-items: center;
}
.imageContainer {
  position: relative;
  /* width: 350px; */
  width: 950px;
  height: 615px;
  @media (max-width: 767px) {
    height: 415px;
  }
  /* margin-right:10px; */

  /* & > img {
    max-width: 100%;
    border-radius: 10px;
    background-size: cover;
  } */
}
.ownerTitle {
  margin-top: 50px;
  font-family: var(--fontFamilyFactor);
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: var(--colorBlack);
  & > div:nth-child(2) {
    margin-left: 10px;
  }
  @media (max-width: 767px) {
    display: none;
  }
}
.eventAvatar {
  height: 20px;
  width: 20px;
}
.seatBoxRoot {
  display: flex;
  width: max-content;
  position: relative;
  gap: 20px;
  align-items: center;
  padding-bottom: 15px;
  & button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 0;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    cursor: pointer;
  }
  & input {
    max-width: 150px;
    text-align: center;
  }
  & .maxSeatsSpan {
    position: absolute;
    font-size: 14px;
    color: #cecece;
    bottom: -8px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
.selectableChild {
  border-bottom: 0.5px solid #e4e4e4;
  padding-bottom: 40px;
}
.eventbookingformchild {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  border-bottom: 0.5px solid #e4e4e4;
  padding-bottom: 40px;
  & > span:first-child {
    font-family: var(--fontFamilyFactor) !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  }
  & > span:last-child {
    border: 1px solid rgb(38 38 38 / 20%);
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    border-radius: 4px;
    color: #000;
    padding: 10px 12px 10px 32px;
    position: relative;
    & svg {
      position: absolute;
      top: 50%;
      left: 5px;
      width: 20px;
      height: 20px;
      transform: translate(0%, -50%);
      stroke: #333333;
    }
  }
}
.clckIcn {
  fill: #fff;
}
.eventbookingform {
  display: flex;
  flex-direction: column;
  gap: 30px;
  & > button {
    border-radius: 100px;
    background-color: #000 !important;
    margin-top: 20px;
    &:disabled {
      background-color: var(--colorGrey100) !important;
      color: var(--colorGrey700) !important;
    }
  }
  & > div:last-child {
    border-bottom: 0 I !important;
  }
}
.bookingModal {
  /* @media(max-width:767px){
   position:fixed !important;
   top:0 !important;
   bottom:0 !important;
   left:0 !important;
   right:0 !important;
   height:100vh !important;
  } */
  & > div {
    & > div {
      flex-basis: 682px !important;
      /* max-width:682px !important; */
      border-bottom: 0 !important;
      border-radius: 10px !important;
      padding: 100px 50px !important;
      & > button {
        @media (max-width: 767px) {
          background-color: #fff !important;
          width: 100% !important;
          justify-content: flex-end !important;
          box-shadow: 0 0 2px 0px rgb(0, 0, 0, 0.2);
          &:hover {
            background-color: #fff !important;
            box-shadow: 0 0 2px 0px rgb(0, 0, 0, 0.2) !important;
          }
        }
      }
      @media (max-width: 767px) {
        flex-basis: auto !important;
        padding: 100px 15px !important;
        width: 100% !important;
      }
    }
  }
}
.radioButtonRow {
  display: flex;
  gap: 30px;
}
.opions {
  & > div {
    & > label {
      font-family: var(--fontFamilyFactor) !important;
      font-size: 14px;
      font-weight: 400;
    }
    & > button {
      border: 1px solid rgb(38 38 38 / 20%) !important;
      margin-top: 10px;
      font-family: var(-fontFamilyHelvetica) !important;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #000;
      @media (max-width: 767px) {
        background-size: 12px;
        padding-right: 30px;
        background-position: top 15px right 16px;
      }
    }
  }
}
.labelWrapper {
  display: flex;
  align-items: flex-start;

  & .labelIcon {
    flex-shrink: 0;
    margin-right: 12px;
    @media (max-width: 767px) {
      padding: 16px 15px;
    }
  }
  & .labelText {
    font-size: 14px;
    line-height: 20px;
    color: var(--colorBlack);
    margin: auto 0px;
    @media (max-width: 767px) {
      font-size: 11px;
    }
    @media (max-width: 575px) {
      margin: 10px 0;
    }
  }
  @media (max-width: 575px) {
    flex-direction: column;
  }
}
.eventModalPrice {
  font-size: 20px;
  line-height: 26px;
  font-family: var(--fontFamilyHelvetica) !important;
  font-weight: 500;
  color: var(--marketplaceColor);
  margin: 0;
}
.sliderImageWrapper {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: contain;
}
.readButton {
  min-height: 50px;
  font-family: 'Factor A';
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 4px;
  @media (max-width: 767px) {
    font-size: 12px;
  }
}
.parentContainer {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.eventReadButton {
  margin-top: 10px;
  font-family: var(--fontFamilyFactor);
  font-size: 14px;
  font-weight: 400;
  color: var(--colorBlack);
  text-decoration: underline;
  cursor: pointer;
  text-underline-offset: 4px;
  @media (max-width: 767px) {
    border-bottom: 0.5px solid #e4e4e4;
    padding-bottom: 20px;
  }
}
.sliderImgContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
  overflow: hidden;
  /* height: 100%;  */
  /* width: 950px; */
  height: 500px;
}
.sliderItem {
  /* position: relative; */
}
.blurredMedia{
  z-index: 0;
  filter: blur(3px);
  object-fit: cover !important;
}