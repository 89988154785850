@import '../../styles/customMediaQueries.css';

.root {
  composes: h4 from global;
  margin-top: 6px;
  margin-bottom: 0;
  color: var(--colorFail);
  overflow: hidden;
  font-size: 13px;
  line-height: 20px;
  font-family:var(--fontFamilyFactor);
  font-weight:500;
  /* margin-top:10px !important; */
  @media (--viewportMedium) {
    /* margin-top: 8px; */
  }
}
