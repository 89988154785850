.root {
  grid-template-rows: auto 1fr auto;
  /* min-height: 100vh; */
  height:100%;
}

.topbar {
  /* position: sticky; */
  top: 0;
  z-index: var(--zIndexTopbar);
  /* max-width: 100vw; */
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
}

.main {
  display: grid;
  /* padding-top: 27px; */
}
