@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.formRow {
  /* This container uses flexbox layout */
  display: flex;
  justify-content: space-between;
  width: 100%;

  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: wrap;

  @media (--viewportSmall) {
    flex-wrap: no-wrap;
  }
  & > div {
    & > input {
      margin-top: 5px;
      font-size: 14px;
      font-family: var(--fontFamilyHelvetica);
      font-weight: 500;
      color: #000;
      @media(max-width:767px){
        min-height:38px;
      }
    }
    & > select{
      margin-top: 5px;
      font-size: 14px;
      font-family: var(--fontFamilyHelvetica);
      font-weight: 500;
      color: #000;
      @media(max-width:767px){
        min-height:38px;
      }
    }
  }
}

.field {
  width: 100%;
  margin-top: 24px;

  @media (--viewportSmall) {
    width: calc(50% - 12px);
  }
  & > label{
    color: #000;
  }
  & > input{
    margin-top:10px;
  }
  @media(max-width:767px){
    margin-top:15px;
  }
}
