.root {
  position: relative;
}
.label {
  color: var(--colorBlack);
}
.valueContainer {
  min-height: 48px;
  border: 1px solid var(--color262626);
  width: 100%;
  padding: 10px 12px;
  padding-right: 48px;
  border-radius: 4px;
  text-align: left;
  color: var(--colorBlack);
  cursor: pointer;
  font-weight: 400;
  box-shadow: none !important;
  background-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 0.5L6 5.5L11 0.5" stroke="black"/></svg>');
  background-repeat: no-repeat;
  background-position: top 50% right 16px;

  &:focus {
    border-color: #80bdff;
  }
}
.openedContainer {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg" transform="rotate(180)"><path d="M1 0.5L6 5.5L11 0.5" stroke="black"/></svg>');
}
.optionWrapper {
  z-index: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color262626);
  border-top-width: 0px;
  position: absolute;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: var(--colorWhite);
  max-height: 240px;
  overflow-y: auto;
  background-color: var(--colorWhite);
  padding:20px;
}
.hiddentArrow {
  cursor: auto !important;
  background-image: none !important;
}

.option {
  cursor: pointer;
  border: 0px;
  outline: none;
  text-align: left;
  padding: 12px 16px;
  color: var(--colorBlack);
  font-weight: 400;
  position: relative;
  & > * {
    pointer-events: none;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 16px;
    width: calc(100% - 32px);
    height: 1px;
    background-color: var(--colorGrey100);
  }
  &:last-child::after {
    background-color: transparent;
  }
}

.selectedOption {
  background-color: var(--colorGrey50);
  font-weight: 500;
}
.hiddenOption {
  display: none;
}

.disabledOption {
  cursor: not-allowed;
  opacity: 0.4;
}
